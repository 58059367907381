.MuiInputLabel-outlined {
  transform: translate(14px, 16px) scale(1) !important;
  color: var(--gris-l63);
  font-family: var(--main-font) !important;
  font-size: 14px !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75) !important;
  font-size: 1rem !important;
}

.MuiButton-contained:hover.Mui-disabled {
  border: 2px solid transparent;
}
